// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-collection-tsx": () => import("./../../../src/pages/collection.tsx" /* webpackChunkName: "component---src-pages-collection-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instances-tsx": () => import("./../../../src/pages/instances.tsx" /* webpackChunkName: "component---src-pages-instances-tsx" */),
  "component---src-pages-labels-tsx": () => import("./../../../src/pages/labels.tsx" /* webpackChunkName: "component---src-pages-labels-tsx" */),
  "component---src-pages-releases-tsx": () => import("./../../../src/pages/releases.tsx" /* webpackChunkName: "component---src-pages-releases-tsx" */)
}

